.wrapper_myAds {
    width: 100%;
    background-color: #f8fafc;
    height: 100%;
}

/*.wrapper_myAds .list {*/
/*  padding-left: 24px;*/
/*  padding-right: 24px;*/
/*}*/

.wrapper_myAds .list .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;

}

.wrapper_myAds .list .title-and-count {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 8px;
    align-items: center;
}

.wrapper_myAds .list .title-and-sortBy {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
}

.wrapper_myAds .list-plugs {
    background-color: #ffffff;
    padding: 8px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 24px;
    border-radius: 8px;
}

.wrapper_myAds .list-plugs .product-plugs {
    display: grid;
    align-items: center;
    position: relative;
}

.wrapper_myAds .list-plugs .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.wrapper_myAds .list .desc {
    display: grid;
    margin-top: 8px;
    margin-bottom: 8px;
}

.wrapper_myAds .list-plugs .text {
    font-size: 14px;
    line-height: 21px;
    color: rgba(71, 85, 105, 0.6);
    max-width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.list-plugs {
    margin-bottom: 20px;
    position: relative;
}

.wrapper_myAds .list-plugs .messageCard,
.wrapper_myAds .list-plugs .heart,
.wrapper_myAds .list-plugs .eye,
.wrapper_myAds .list-plugs .clock,
.wrapper_myAds .list-plugs .map {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
}

.wrapper_myAds .list-plugs .map {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
}

.list-plugs .inf-product .heart-img {
    width: 18px;
    height: 18px;
}

.list-plugs .inf-product span {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list .list-plugs .price-and-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.price-and-actions .non-active-payed{
    display: none;
}

.list .list-plugs .price-and-actions .price {
    padding-right: 8px;
}

.list .list-plugs .price-and-actions .price span {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.list .list-plugs .price-and-actions .actions {
    display: grid;
    grid-template-columns: repeat(4, max-content);
}

.list .list-plugs .price-and-actions img.action {
    cursor: pointer;
}

.MuiPagination-ul {
    justify-content: center;
}

.wrapper_myAds .list-menu .img-and-title .my-ads {
    background: #eef0fb;
    border-radius: 8px;
}


.selling-character {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.selling-character > h4 {
    font-weight: bold;
    font-size: 24px;
    margin-top: 20px;
    color: #475569;
}

.selling-character > span {
    font-weight: 500;
    font-size: 16px;
    color: #475569;
}

.btn-sell {
    display: inline-block;
    background: #2f49d1;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    text-transform: capitalize;
    padding: 10px 40px;
    font-weight: 600;
    font-size: 16px;
    margin-top: 25px;
    transition: 300ms;
}

.btn-sell:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.wrapper_myAds .desc .inf-product .ads-reactions {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 10px;
}

.product-plugs-img {
    width: 168px;
    height: 135px;
    max-width: 100%;
    object-fit: cover;
}

.wrapper_myAds .ad_list_top_date {
    display: flex;
    justify-content: center;
    background-color: #20C576;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px .5rem;
}

 .wrapper_myAds .ad_list_top_date > span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

@media (max-width: 700px) {
    .wrapper_myAds .list-plugs {
        display: flex;
        flex-direction: column;
        padding: 16px;
        grid-gap: 5px;
    }

    .wrapper_myAds .ad_list_top_date {
        border-radius: 0 0 0 8px;
    }

    .wrapper_myAds .product-plugs {
        grid-template-columns: max-content max-content;
    }

    .wrapper_myAds .date-loc {
        margin-left: 16px;
        display: flex;
        flex-direction: column;
    }

    .wrapper_myAds .date {
        font-size: 12px;
        line-height: 16px;
        color: #94A3B8;
    }

    .wrapper_myAds .date-loc a .title {
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        margin-top: 8px;
        max-width: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }

    .wrapper_myAds .date-loc .price {
        margin-top: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #475569;
    }

    .wrapper_myAds .product-plugs .product {
        object-fit: cover;
        border-radius: 8px;
        width: 147px;
        height: 89px;
    }

    .wrapper_myAds .reactions-activate {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wrapper_myAds .reactions-archive {
        display: flex;
        grid-gap: 15px;
    }

    .wrapper_myAds .reactions-archive .heart .heart-img {
        width: 18px;
        height: 18px;
    }

    .wrapper_myAds .list-plugs .action {
        width: 36px;
        height: 36px;
    }

    .wrapper_myAds .list-plugs .dots-more {
        margin-left: 8px;
        cursor: pointer;
    }

    .wrapper_myAds .list .title-and-sortBy {
        padding: 20px 20px 5px 20px;

    }

    .list-plugs {
        margin-bottom: unset;
    }

    .product-plugs-img {
        border-radius: 8px;
        width: 147px;
        height: 89px;
    }
}


