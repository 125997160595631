.wrapper_followed_ads {
    width: 100%;
    background-color: #F8FAFC;
}

.wrapper_followed_ads .title-and-sortBy {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    padding-bottom: 40px;
    grid-gap: 12px;
}

.wrapper_followed_ads .title-and-count .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.wrapper_followed_ads .title-and-count {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 8px;
    align-items: center;
}

.wrapper_followed_ads .sort-by .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.wrapper_followed_ads .empty-page-followed {
    display: grid;
    justify-content: center;
    justify-items: center;
}

.wrapper_followed_ads .dont-follow {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

.wrapper_followed_ads .use-heart {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-top: 8px;
}

.wrapper_followed_ads .use-heart-img {
    margin-top: 24px;
    display: grid;
    align-items: center;
}

@media (max-width: 700px) {
    .MuiGrid-container{
        display: flex;
        justify-content: center;
    }
    .wrapper_followed_ads .title-and-sortBy{
        margin-top: 15px;
    }
    .wrapper_followed_ads{
        padding: 0 17px;
    }
}