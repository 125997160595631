:root {
    /*--dark-grey: #2b2b2e;*/
    /*--ugly-purple: #bc4386;*/
    /*--turquoise: #01afa8;*/
    /*--slate: #4e5c6a;*/
    /*--lite-grey: #dbddde;*/
    /*--pale-grey: #f5fcff;*/
    /*--grey: #cccbcb;*/
    /*--error: #F26464;*/
    /*--success: #4BDE97;*/
    /*--white: #f5f5f5;*/
    /*--beige: #F6F6F1;*/
    /*--new-error: #FF4949;*/
    --primary-blue: #2F49D1;
    --error: #F26464;
    --app-height: 100vh;
}

*, ::after, ::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    color: unset;
    font-size: unset;
    line-height: unset;
    background: #F8FAFC;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    min-width: 320px;
}

button, textarea,
input, a,
input:focus,
button:focus,
textarea:focus {
    outline: none;
    text-decoration: none;
}

button {
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.wrapper_app {
    margin: 76px 0 0;
    min-height: calc(var(--app-height) - 76px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.load_circle_wrapper {
    display: flex;
    justify-content: center;
    place-items: center;
    min-height: 250px;
    height: 100%;
}

.overflow_scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 10px;
}

.overflow_chat_scrollbar::-webkit-scrollbar {
    width: 10px;
}

.overflow_scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px #ededed;
}

.overflow_scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--primary-blue);
    border: 1px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}

picture {
    display: grid;
    align-items: center;
    width: 100%;
}

.MuiTooltip-tooltip {
    background: #000000 !important;
    color: #FFFFFF !important;
    font-size: 12px !important;
}

.MuiTooltip-arrow {
    color: #000000 !important;
}

@media (max-width: 830px) {
    .wrapper_app {
        margin: 48px 0 0;
        min-height: calc(var(--app-height) - 48px);
    }
}