.wrapper_user_invoices {
    width: 100%;
    background-color: #F8FAFC;
}

.wrapper_user_invoices .title-and-prepay {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
}

.wrapper_user_invoices .title-and-prepay .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.wrapper_user_invoices .title-and-prepay .balance-prepay {
    display: grid;
    grid-gap: 16px;
    margin-top: 15px;
}

.wrapper_user_invoices .title-and-prepay .balance-prepay .your-balance {
    display: flex;
    align-items: center;
}

.wrapper_user_invoices .title-and-prepay .balance-prepay .wallet {
    margin-right: 8px;
}

.wrapper_user_invoices .title-and-prepay .balance-prepay .balance-word {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.wrapper_user_invoices .title-and-prepay .balance-prepay .tops {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #F57C1F;
    text-transform: uppercase;
    margin-left: 16px;
}

.wrapper_user_invoices .prepay-button {
    background: #EEF0FB;
    border-radius: 8px;
    border: none;
    padding: 6px 80px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2F49D1;
    transition: 300ms;
}

.wrapper_user_invoices .prepay-button:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

.wrapper_user_invoices .block-invoices-and-payment .tabs-payment-invoices {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 62, 71, .2);
    justify-content: space-between;
    width: 400px;
}


.wrapper_user_invoices .active_tab {
    border-bottom: 2px solid #2f49d1;
    color: #2f49d1 !important;
}

.wrapper_user_invoices .tab {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    padding-bottom: 12px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    color: #94A3B8;
    width: 200px;
    justify-content: center;
}


.wrapper_user_invoices .word-inv {
    margin-left: 16px;
}

.wrapper_user_invoices .word-pay {
    padding-right: 42px;
}

.wrapper_user_invoices .block-invoices-and-payment .packages {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    margin-top: 64px;
    grid-gap: 24px;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type {
    background-color: #FFFFFF;
    display: grid;
    max-width: 302px;
    position: relative;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .img-type {
    display: flex;
    padding: 24px 24px 8px 24px;
    align-items: center;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .img-type .type {
    margin-left: 8px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .tops {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #F57C1F;
    text-transform: uppercase;
    padding-left: 24px;
    padding-bottom: 12px;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .topup {
    font-size: 12px;
    line-height: 16px;
    color: #94A3B8;
    padding: 0 24px 16px 24px;
    border-bottom: 1px solid #E2E8F0;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .text {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    padding: 16px 24px 32px 24px;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .pay {
    width: 100%;
    border: 1px solid #2F49D1;
    border-radius: 8px;
    padding: 6px 16px;
    display: grid;
    align-items: center;
    text-align: center;
    background: #FFFFFF;
    cursor: pointer;
    transition: .3s;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .pay span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2f49d1;
    transition: .3s;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .pay:hover {
    background: var(--primary-blue);
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .pay:hover span {
    color: #FFFFFF;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .button-pay {
    padding: 0 24px 24px 24px;
}

.discount-package {
    background: #20C576;
    padding: 4px 16px;
    position: absolute;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    top: 15px;
    right: 0;
}

.no-discount-package {
    background: #636161FF;
    padding: 4px 16px;
    position: absolute;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    top: 15px;
    right: 0;
}

.wrapper_user_invoices .block-invoices-and-payment .packages .package-type .block-pay {
    padding: 0 24px 24px;
}

.prepay-back {
    display: grid;
    grid-gap: 10px;
}

.prepay-back .back-to-invoices {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    cursor: pointer;
}

.prepay-back .back-to-invoices span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

@media (max-width: 1335px) {
    .wrapper_user_invoices .block-invoices-and-payment .packages {
        grid-template-columns: unset;
        justify-content: center;
    }
}

@media (max-width: 960px) {
    .wrapper_user_invoices .title-and-prepay {
        grid-template-columns: unset;
        justify-content: flex-start;
        padding: 16px;
        display: flex;
        flex-direction: column;
    }

    .empty_list_wrap {
        padding: 0 35px;
    }

    .wrapper_user_invoices .block-invoices-and-payment .tabs-payment-invoices {
        width: unset;
    }

    .wrapper_user_invoices .title-and-prepay .balance-prepay {
        justify-content: center;
    }
}




