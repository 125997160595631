.wrapper_invoices {
    overflow-x: auto;
    width: 100%;
}

.wrapper_invoices_empty {
    overflow-x: hidden;
}

.wrapper_invoices .title-invoices {
    width: 100%;
    display: grid;
    padding: 16px 24px;
    border-bottom: 1px solid #E2E8F0;
    grid-gap: 0 1.5rem;
    background: #FFFFFF;
}

.wrapper_invoices .title-invoices span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.wrapper_invoices .inf-invoices {
    width: 100%;
    display: grid;
    padding: 16px 24px;
    grid-gap: 0 1.5rem;
    background: #FFFFFF;
}

.wrapper_invoices .inf-invoices-bg {
    background: #F8FAFC;
}

.wrapper_invoices .file-download {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

.wrapper_invoices .file-download svg path {
    transition: .2s;
}

.wrapper_invoices .file-download:hover svg path {
    stroke: #2F49D1;
}

.wrapper_invoices .inf-invoices .date,
.wrapper_invoices .inf-invoices .number {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.wrapper_invoices .inf-invoices .period {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

@media (max-width: 1400px) {
    .wrapper_invoices .title-invoices, .wrapper_invoices .inf-invoices {
        width: fit-content;
    }
}

@media (max-width: 700px) {
    .wrapper_invoices .title-invoices, .wrapper_invoices .inf-invoices {
        width: unset;
    }

    .wrapper_invoices .inf-invoices {
        display: grid;
        grid-template-columns: max-content max-content;
        justify-content: space-between;
    }

    .wrapper_invoices .date-number {
        display: grid;
        grid-template-columns: 1fr max-content;
        justify-content: space-between;
        padding: 16px 24px;
        align-items: center;
        grid-gap: .5rem;
    }

    .wrapper_invoices .date-number .date-mob {
        display: flex;
        flex-direction: column;
    }

    .wrapper_invoices .date-number .date-mob .title-date {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #94A3B8;
    }

    .wrapper_invoices .date-number .date-mob .date {
        font-size: 14px;
        line-height: 21px;
        color: #475569;
    }

}