.wrapper_ad_detail .ad-blocks-similar,
.wrapper_ad_detail .ad-blocks {
    display: grid;
    grid-template-columns: repeat(5, minmax(240px, 1fr));
    grid-gap: 1.25rem;
    align-items: center;
}

/*.block-a {*/
/*    background: #ffffff;*/
/*    border-radius: 8px;*/
/*    margin-top: 24px;*/
/*    margin-right: 20px;*/
/*}*/

.wrapper_ad_detail {
    background: rgb(248, 250, 252);
    padding: 40px 80px;
}

.raise-button {
    background: #20C576;
    border-radius: 8px;
    margin: 0 auto .5rem;
    padding: 6px 18px;
    height: 40px;
}

.raise-button .top {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    color: white;
    white-space: nowrap;
}

.adDetail_img_owner p,
.adDetail_img_owner span,
.ownersAd-similarAd p,
.ownersAd-similarAd span,
.searched-for .title {
    color: #475569;
}

.ownersAd-similarAd .block-inf .views span {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.desc-product p {
    margin-top: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
}

.adDetail_img_owner {
    display: grid;
    grid-template-columns: calc(100% - 411px - 24px) 411px;
    grid-gap: 24px;
}

.adDetail_img_owner .img-product {
    background: #ffffff;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 16px;
    position: relative;
}

.adDetail_img_owner .image_slider_container {
    height: 100%;
    display: grid;
    align-items: center;
}

.adDetail_img_owner .img-product .detail_ad_slider {
    cursor: grab;
}

.adDetail_img_owner .img-product .detail_ad_slider .slider_arrow_left, .adDetail_img_owner .img-product .detail_ad_slider .slider_arrow_right {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    display: grid !important;
    place-items: center;
    z-index: 100;
    cursor: pointer;
}

.adDetail_img_owner .img-product .detail_ad_slider .slider_arrow_left {
    left: .5rem;
}

.adDetail_img_owner .img-product .detail_ad_slider .slider_arrow_right {
    right: .5rem;
}

.adDetail_img_owner .img-product .detail_ad_slider .slick-dots {
    bottom: 8px;
}

.adDetail_img_owner .img-product .detail_ad_slider .slick-dots li button:before {
    color: rgba(148, 163, 184, 0.65);
    transition: .2s;
    opacity: 1;
    font-size: 8px;
}

.adDetail_img_owner .img-product .detail_ad_slider .slick-dots li:hover button:before {
    color: rgba(148, 163, 184, 1);
}

.adDetail_img_owner .img-product .detail_ad_slider .slick-dots li.slick-active button:before {
    color: #FFFFFF;
}

.img-product .product {
    width: 100%;
    object-fit: contain;
    max-height: 50vh;
    cursor: zoom-in;
    /*width: 100%;*/
    /*height: 100%;*/
    /*max-height: 610px;*/
    /*object-fit: cover;*/
}

.desc_owner {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    display: grid;
    grid-gap: 16px;
}

.desc_owner .owner-word {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.owner-rating {
    display: flex;
    gap: 16px;
    align-items: center;
    width: max-content;
}

/*.owner-rating .user-photo {*/
/*    width: 64px;*/
/*    height: 64px;*/
/*    border-radius: 50%;*/
/*    position: relative;*/
/*}*/

.details_default {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    background: #CBD5E1;
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
}

.details_default > img {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    object-fit: cover;
}

.details_default span {
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: #FFFFFF;
    text-transform: capitalize;
    font-family: 'Inter', sans-serif;
}

.owner-rating .rating-and-name {
    display: grid;
    align-items: center;
}

.owner-rating .rating-and-name span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    max-width: 280px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.price-and-contacts {
    margin-top: 16px;
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
}

.price-and-contacts .time {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94a3b8;
    display: grid;
}

.time-and-title {
    display: grid;
    grid-template-columns: 6fr 1fr max-content;
    grid-gap: .5rem;
    justify-content: space-between;
}

.cat-link{

    background: #ffffff;
    padding: 16px;
    /*border-radius: 8px;*/
    /*padding: 12px 4px;*/
    display: flex;
    /*justify-content: center;*/
    cursor: pointer;
}

.cat-link p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    margin: 0;
}

.favourite {
    background: #eef0fb;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: grid;
    justify-items: center;
    align-items: center;
    cursor: pointer;
}

.time-and-title .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.price-and-contacts .price {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
}

.message-and-number {
    display: grid;
    grid-gap: 8px;
    margin-bottom: 12px;
}

.message-and-number .message {
    background: #2f49d1;
    border-radius: 8px;
    padding: 12px 4px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.message-and-number .message:hover {
    opacity: 0.8;
}

.wrapper_ad_detail .message span {
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.phone-number {
    padding: 12px 4px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #2f49d1;
    border-radius: 8px;
}

.phone-number .show {
    display: flex;
}

/*.phone-number .reshow {*/
/*    display: none;*/
/*}*/

/*.phone-number:hover .show {*/
/*    display: none;*/
/*}*/

/*.phone-number:hover .reshow {*/
/*    display: inline;*/
/*}*/

.phone-number span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2f49d1;
}

.phone-number .phone {
    margin-right: 6px;
}

.desc_adDetail .location {
    background: #ffffff;
    border-radius: 8px;
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: .5rem;
    padding: 16px;
}


.location-no-access {
    margin-top: 16px;
    margin-bottom: 16px;
}

.desc_adDetail .location-no-access .user_geolocation {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 .5rem;
}

.desc_adDetail .location-mob .title,
.desc_adDetail .location .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    display: block;
    margin: 0 0 1rem;
}

.desc_adDetail .location .user_geolocation {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 8px;
    margin: 0 0 .5rem;
}

.cat-link,
.block-inf .map-pin,
.map-and-location p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

.block-inf .user-avatar {
    border-radius: 50%;
    width: 18px;
    height: 18px;
}

.block-inf .views,
.block-inf .loc_block_inf {
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    grid-gap: 10px;
}

.desc_adDetail .location .km {
    font-size: 14px;
    line-height: 21px;
    color: #94a3b8;
}

.desc_adDetail .tags {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
    display: grid;
    grid-gap: 15px;
}

.tags .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
}

.tags .tags-filter {
    display: flex;
    grid-gap: 20px;
    flex-wrap: wrap;
    /*justify-content: normal;*/
}

.tags-filter .name-of-filter {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    padding: 2px 16px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
}

.attrs-list .name-of-attr {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /*border: 1px solid #cbd5e1;*/
    border-radius: 8px;
    padding: 2px 16px;
    display: flex;
    align-content: center;
    column-gap: 6px;
    inline-size: max-content;
    overflow: hidden;
    overflow-wrap: break-word;

    /*word-wrap: break-word;*/
    max-width: 170px;
    /*cursor: pointer;*/
    /*transition: .2s;*/
}

.name-of-attr span {
    word-wrap: break-word;
    /*overflow: hidden;*/
    white-space: nowrap;
    text-overflow: ellipsis;
}

.attrs .attrs-list {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    justify-content: space-evenly;
}

.attrs-list .name-of-attr:hover {
    color: unset;
}

.desc_adDetail .attrs {
    background: #ffffff;
    border-radius: 8px;
    padding: 16px;
    /*margin-top: 16px;*/
    display: grid;
    grid-gap: 15px;
}

.tags-filter .name-of-filter:hover {
    background: #2f49d1;
    color: #ffffff;
    cursor: pointer;
}

.tags-filter .name-of-filter span {
    transition: .2s;
    color: #475569;
}

.tags-filter .name-of-filter:hover span {
    color: #FFFFFF;
}

.desc-product {
    margin-top: 24px;
    background-color: #ffffff;
    padding: 16px 24px;
}

.desc-product .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
}

.desc-product .complain-and-likes {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    justify-content: space-between;
}

.complain-and-likes .complain {
    display: flex;
    cursor: pointer;
}

.complain-and-likes {
    margin-top: 52px;
}

.complain .flag {
    margin-right: 8px;
}

.likes-and-view {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 30px;
}

.likes-and-view .views,
.likes-and-view .likes {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
}

.likes-and-view .likes .likes-heart {
    width: 18px;
    height: 18px;
}

.likes-and-view .likes .detail-heart {
    width: 18px;
    height: 18px;
}

.owner-ads {
    margin-top: 88px;
    position: relative;
}

.searched-for .title,
.similar-ads .title,
.owner-ads .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    display: block;
    margin: 0 0 1.5rem;
}

.block-a {
    background: #ffffff;
    border-radius: 8px;
    height: 100%;
    position: relative;
}

.block-a > a {
    display: grid;
    align-items: center;
    position: relative;
}

.block-a > a img {
    width: 100%;
    height: 224px;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
}

.block-a .date-ad {
    font-size: 14px;
    line-height: 21px;
    color: #94a3b8;
}

.block-a .date-and-price {
    display: grid;
    grid-template-columns: 1fr max-content;
    padding: 8px 16px;
}

.date-and-price .price {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.block-a .block-inf {
    padding: 0 16px 24px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
}

.block-a .block-inf .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: unset;
    width: 220px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.block-inf .likes-and-view {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.ownersAd-similarAd .similar-ads {
    margin-top: 40px;
}

.wrapper_ad_detail .searched-for {
    margin-top: 40px;
    padding: 16px;
}


.block-a .block-inf p {
    margin-top: 8px;
    margin-bottom: 8px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.message-sent {
    display: flex;
    justify-content: center;
}

.message-info {
    background: #EEF0FB;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    width: 20%;
    padding: 3px 12px;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
}

.message-info > span {
    color: #2f49d1 !important;
    font-weight: 600;
    font-size: 18px;
}

.searched-for .also_search {
    display: flex;
    flex-wrap: wrap;
    gap: .5rem 1rem;
    margin: 1.5rem 0;
}

.location-mob {
    display: none;
    background: #ffffff;
    border-radius: 8px;
    margin-top: 16px;
    padding: 16px;
}

.location-mob .user_geolocation {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-gap: .5rem;

}

.similar_top_wrapper {
    position: relative;
}

.details_top_date, .owner_top_date, .similar_top_date {
    display: flex;
    justify-content: center;
    background-color: #20C576;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px 8px;
}

.details_top_date {
    bottom: 1rem;
    left: 1rem;
}

.details_top_date > span, .owner_top_date > span, .similar_top_date > span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

@media (max-width: 1480px) {
    .searched-for .filters-in-top {
        grid-gap: 20px;
    }
}

@media (max-width: 1440px) {
    .wrapper_ad_detail .ad-blocks-similar,
    .wrapper_ad_detail .ad-blocks {
        grid-template-columns: repeat(4, minmax(240px, 1fr));
    }
}

@media (max-width: 1200px) {
    .wrapper_ad_detail .ad-blocks-similar,
    .wrapper_ad_detail .ad-blocks {
        grid-template-columns: repeat(3, minmax(240px, 1fr));
    }
}

@media (max-width: 1070px) {
    .wrapper_ad_detail {
        padding: unset;
    }

    .adDetail_img_owner {
        grid-template-columns: 100%;
        grid-gap: 16px;
    }

    .similar-ads,
    .owner-ads {
        padding: 16px;
    }

    .complain-and-likes .likes-and-view {
        position: absolute;
        right: 16px;
        top: 16px;
    }

    .desc-product {
        position: relative;
        margin-top: 16px;
    }

    .desc_owner {
        grid-row-start: 2;
        margin-top: 16px;
    }

    .desc_adDetail {
        display: grid;
    }

    .price-and-contacts {
        margin-top: unset;
    }

    .desc_adDetail {
        display: grid;
    }

    .location-no-access {
        display: none;
    }

    .location-mob {
        display: unset;
    }

    .desc-product .complain-and-likes {
        grid-template-columns: repeat(2, max-content);
    }
}

@media (max-width: 800px) {
    .wrapper_ad_detail .ad-blocks-similar,
    .wrapper_ad_detail .ad-blocks {
        grid-template-columns: repeat(2, minmax(240px, 1fr));
    }
}

@media (max-width: 580px) {
    .wrapper_ad_detail .searched-for,
    .ownersAd-similarAd .similar-ads,
    .owner-ads {
        margin-top: 32px;
    }

    .wrapper_ad_detail .ad-blocks-similar, .wrapper_ad_detail .ad-blocks {
        grid-template-columns: 1fr 1fr;
    }

    .block-a > a > img {
        height: 160px;
    }

    .block-inf .likes-and-view,
    .block-a .views {
        display: none;
    }

    .block-a .favourite {
        width: 32px;
        height: 32px;
    }

    .block-a .block-inf,
    .block-a .date-and-price {
        padding: 8px;
    }

    .adDetail_img_owner .img-product {
        padding: unset;
    }

    .details_top_date {
        bottom: 0;
        left: 0;
    }

    .tags .tags-filter {
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
    }

    .desc-product {
        margin-top: unset;
        position: relative;
        padding: 16px;
    }

    .adDetail_img_owner {
        grid-gap: 16px;
    }

    .wrapper_ad_detail .searched-for {
        padding: 21px;
    }

    .searched-for .filters-in-top {
        justify-content: unset;
        margin: 18px 0 35px 0;
    }

    .block-a .block-inf .title {
        width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }

    .block-a .block-inf p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }

    .owner_top_date > span {
        top: 37%;
    }

    .similar_top_date > span {
        top: 76%;
    }

    .details_top_date > span {
        top: 94%;
        left: 1px
    }

}

@media (max-width: 440px) {
    .block-a > a > img {
        height: 112px;
    }

    .wrapper_ad_detail .ad-blocks-similar, .wrapper_ad_detail .ad-blocks {
        grid-gap: 9px;
    }

    .similar_top_date > span {
        top: 67%;
    }

    .owner_top_date > span {
        top: 26.3%;
    }

    .details_top_date > span {
        top: 89.6%;
        left: 1px
    }
}


@media (max-width: 400px) {
    .ad-blocks .block-a {
        margin-right: unset;
    }

    .block-a .block-inf p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
    }
}
