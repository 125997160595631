.notifications-page {
    height: 100%;
}

.wrapper_notifications {
    padding: 24px 16px;
    background: #F8FAFC;
    height: 100%;
    width: 100%;
    text-align: left;
}

.wrapper_notifications .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.wrapper_notifications .new-notifications .new {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 16px;
    margin: 1rem 0;
}

.wrapper_notifications .new-notifications .older_notification > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 16px;
    margin: 1rem 0;
    display: block;
}

.wrapper_notifications .new-notifications .img-desc {
    border-bottom: 1px solid #E2E8F0;
    padding-bottom: 16px;
}


