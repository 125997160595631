.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
}


.modal-content-complaint {
    transition: 0.5s;
    padding: 24px 24px 40px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 15px;
}

.modal .close_icon {
    cursor: pointer;
}

.modal.active, .modal.active .modal-content-complaint {
    transform: scale(1);
    transition: 0.5s;
}


.close-and-complaint {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.close-and-complaint .close-button {
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-complaint .complaint {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
}

.close-and-complaint .complaint span {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

.modal-content-complaint .whats-wrong {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.modal-content-complaint .file-and-send .send {
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
}


.checks {
    display: grid;
    grid-gap: 12px;
}

.checks input[type="radio"] {
    display: block;
    margin: 0;
    width: 18px;
    height: 18px;
    position: relative;
}

.checks input[type='radio']::before {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #475569;
}

.checks input[type='radio']:checked::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2F49D1;
    visibility: visible;
    top: 4px;
    left: 4px;
}

.checks input[type='radio']:checked::before {
    border: 2px solid #2F49D1;

}


/*.input-checkbox {*/
/*    position: absolute;*/
/*    z-index: -1;*/
/*    opacity: 0;*/
/*}*/

.input-checkbox + label {
    /*display: grid;*/
    /*grid-template-columns: max-content max-content;*/
    /*align-items: center;*/
    /*grid-gap: 10px;*/
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}

.radio-check {
    display: inline-grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
    cursor: pointer;
}

/*.input-checkbox + label::before {*/
/*    content: '';*/
/*    display: inline-block;*/
/*    width: 13px;*/
/*    height: 13px;*/
/*    flex-shrink: 0;*/
/*    flex-grow: 0;*/
/*    border-radius: 50%;*/
/*    margin-right: 0.5em;*/
/*    background-repeat: no-repeat;*/
/*    background-position: center center;*/
/*    background-size: 50% 50%;*/
/*    border: 2px solid #475569;*/
/*}*/


/*.input-checkbox:not(:disabled):not(:checked) + label:hover::before {*/
/*    border: 2px solid #475569;*/
/*}*/


/*.input-checkbox:not(:disabled):active + label::before {*/
/*    border: 2px solid #21143B;*/
/*}*/

/*.input-checkbox:checked + label::before {*/
/*    border: 2px solid #2F49D1;*/
/*    background-image: url("../PopupComplain/assets/small-circle.svg");*/

/*}*/

.modal-content-complaint .send {
    width: 100%;
    display: block;
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    margin-top: 25px;
}

.modal-content-complaint .send:hover {
    opacity: 0.8;
}

.compliant-sent {
    display: flex;
    justify-content: center;
}

.compliant-info {
    background: #EEF0FB;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    width: 40%;
    padding: 3px 30px;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
}

.compliant-info > span {
    color: #2f49d1 !important;
    font-weight: 600;
    font-size: 18px;
}

/*.compliant-error {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    background: #FFFFFF;*/
/*    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
/*    width: 80%;*/
/*    height: 50px;*/
/*}*/

.compliant-error {
    position: absolute;
    top: -4.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 80%;
    height: 50px;
    visibility: hidden;
    opacity: 0;
    transition: .2s;
}

.compliant-error-active {
    visibility: visible;
    opacity: 1;
}