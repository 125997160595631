.wrapper_payment {
    overflow-x: auto;
    width: 100%;
}

.wrapper_payment_empty {
    overflow-x: hidden;
}

.wrapper_payment .title-payment {
    width: 100%;
    display: grid;
    padding: 16px 24px;
    border-bottom: 1px solid #E2E8F0;
    grid-gap: 0 1.5rem;
    background: #FFFFFF;
}

.wrapper_payment .title-payment span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.wrapper_payment .inf-payment {
    width: 100%;
    display: grid;
    padding: 16px;
    grid-gap: 0 1.5rem;
    background: #FFFFFF;
}

.wrapper_payment .inf-payment-bg {
    background: #F8FAFC;
}

.wrapper_payment .paid-and-title {
    display: grid;
}

.wrapper_payment .inf-payment .number,
.wrapper_payment .inf-payment .date {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.wrapper_payment .inf-payment .amount,
.wrapper_payment .paid-and-title .paid {
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.wrapper_payment .paid-and-title .title {
    font-size: 14px;
    line-height: 21px;
    color: #2F49D1;
}

.wrapper_payment .inf-payment .file-search {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
}

.wrapper_payment .file-search svg path {
    transition: .2s;
}

.wrapper_payment .file-search:hover svg path {
    stroke: #2F49D1;
}

.wrapper_payment .amount-number {
    display: flex;
    justify-content: center;
}

@media (max-width: 1366px) {
    .wrapper_payment .title-payment, .wrapper_payment .inf-payment {
        width: fit-content;
    }
}

@media (max-width: 700px) {
    .wrapper_payment .title-payment, .wrapper_payment .inf-payment {
        width: 100%;
        padding: 1rem;
    }

    .wrapper_payment .amount-number {
        justify-content: unset;
    }

    .wrapper_payment .date-mob {
        padding-top: 8px;
        display: flex;
        flex-direction: column;
    }

    .wrapper_payment .date-mob .date {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #475569;
    }


    .wrapper_payment .date-mob .title-date {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #94A3B8;
    }

    .wrapper_payment .date-number {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        padding: 16px;
        align-items: end;
    }
}

@media (max-width: 380px) {
    .wrapper_payment .date-number {
        grid-template-columns: 1fr;
        grid-gap: .5rem;
    }
}

@media (max-width: 325px) {
    .wrapper_payment .paid-and-title .title {
        word-break: break-all;
    }
}