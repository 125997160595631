.wrapper_archive {
    background: rgb(248, 250, 252);
}

.wrapper_archive .archive-plugs .title-and-sortBy {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    padding-bottom: 40px;
}

.wrapper_archive .archive-plugs .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.wrapper_archive .archive-plugs .title-and-count {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 8px;
    align-items: center;
}

.wrapper_archive .list-plugs .product-plugs {
    position: relative;
}

.wrapper_archive .list-plugs {
    background-color: #FFFFFF;
    padding: 8px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: 15px;
    border-radius: 8px;
}

.wrapper_archive .list-plugs a {
    display: flex;
    align-items: center;
}

.wrapper_archive .list-plugs .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.wrapper_archive .desc {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
}

.wrapper_archive .list-plugs .text {
    font-size: 14px;
    line-height: 21px;
    color: rgba(71, 85, 105, 0.6);
    max-width: 600px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.wrapper_archive .list-plugs .messageCard,
.wrapper_archive .list-plugs .heart,
.wrapper_archive .list-plugs .eye,
.wrapper_archive .list-plugs .clock,
.wrapper_archive .list-plugs .map {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 10px;
}

.wrapper_archive .list-plugs .map {
    grid-template-columns: max-content 1fr;
}

.list-plugs .inf-product {
    display: grid;
    grid-template-columns: 250px 101px 130px;
    grid-gap: 18px;
    align-items: end;
    margin-top: 6px;
}

.list-plugs .inf-product span {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.wrapper_archive .price-and-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 8px;
}

.wrapper_archive .list-plugs .price-and-actions .price span {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.wrapper_archive .list-plugs .price-and-actions .actions {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 12px;
    padding-bottom: 7px;
}

.wrapper_archive .list-plugs .price-and-actions .action {
    cursor: pointer;
}

.MuiPagination-ul {
    justify-content: center;
}

.wrapper_archive .list-plugs .archive-reactions {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 10px;
}

.number-reactions {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 38px;
}

.wrapper_archive .ad_archive_top_date {
    display: flex;
    justify-content: center;
    background-color: #20C576;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2px .5rem;
    color: #FFFFFF;
}

.wrapper_archive .ad_archive_top_date > span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
}

@media (max-width: 1300px) {
    .list-plugs .inf-product {
        grid-template-columns: unset;
        margin-top: 16px;
        grid-gap: 5px;
    }

    .wrapper_myAds .list-plugs .clock {
        display: none;
    }
}

@media (max-width: 700px) {
    .actions-mob {
        display: flex;
        grid-gap: 10px;
    }

    .wrapper_archive .list-plugs {
        display: flex;
        flex-direction: column;
        padding: 16px;
        grid-gap: 5px;
    }

    .wrapper_archive .product-plugs {
        display: grid;
        grid-template-columns: 147px 1fr;
    }

    .wrapper_archive .product-plugs .product {
        width: 147px;
        height: 89px;
        border-radius: 8px;
        object-fit: cover;
    }

    .wrapper_archive .date {
        font-size: 12px;
        line-height: 16px;
        color: #94A3B8;
    }

    .wrapper_archive .date-loc {
        margin-left: 16px;
        display: grid;
        align-items: center;
    }

    .wrapper_archive .date-loc .title {
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        margin-top: 8px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        word-break: break-word;
    }

    .wrapper_archive .date-loc .price {
        margin-top: 8px;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #475569;
    }

    .wrapper_archive .reactions-archive {
        display: flex;
        grid-gap: 15px;
    }

    .archive-list-wrap {
        padding: 16px;
    }

    .wrapper_archive .reactions-archive .heart .heart-img {
        width: 18px;
        height: 18px;
    }

    .wrapper_archive .reactions-activate {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wrapper_archive .list-plugs .action {
        width: 36px;
        height: 36px;
    }

    .wrapper_archive .list-plugs .dots-more {
        margin-left: 8px;
    }

    .wrapper_archive .archive-plugs .title-and-sortBy {
        padding: 20px;
    }

    .wrapper_archive .ad_list_top_date {
        display: flex;
        justify-content: center;
        background-color: #20C576;
        align-items: center;
        position: absolute;
        left: 0;
        padding: 2px 0.5rem;
        border-radius: 0 0 0 8px;
        color: #FFFFFF;
        bottom: 0;
    }

    .wrapper_archive .ad_list_top_date > span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 21px;
        color: #FFFFFF;
    }
}

