.user-name-profile {
    color: #2f49d1;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    margin-left: 8px;
}

.person {
    width: 20px;
    height: 20px;
}

.person-icon {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background: #CBD5E1;
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .1s;
}

.person-icon img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    user-select: none;
}

.person-icon span {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: capitalize;
}

.auth_initials {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: #CBD5E1;
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
}

.auth_initials span {
    font-size: 15px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: capitalize;
}

.auth_initials > .user_avatar {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
}

.person-icon img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_default {
    width: 32px;
    border-radius: 100%;
    object-fit: cover;
    height: 32px;
}

.name-user-block {
    border-bottom: 1px solid #e2e8f0 !important;
    width: 100% !important;
    padding: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.item-menu {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
    transition: .2s;
}

.menu-header .logout-block {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
    transition: .2s;
}


.menu-header .logout-block span,
.item-menu span {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.menu-header .logout-block:hover,
.item-menu:hover {
    background-color: #E5E5E5;
}

.menu-header li img {
    margin-right: 10px;
}

.menu-header li:first-of-type img {
    margin-right: 0;
}

.menu-header .logout-block {
    border-top: 1px solid #e2e8f0;
}

.MuiList-padding {
    padding: 0;
}

.theme-menu {
    color: #475569;
    background-color: #FFFFFF;
    top: 4.25rem !important;
    border-radius: 8px !important;
    left: unset !important;
    right: 3.5rem !important;
    position: absolute;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
    overflow: auto;
}

.style-menu-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 70px;
    z-index: 6000;
}

.person-icon:hover {
    filter: drop-shadow(0 2px 8px rgba(255, 255, 255, 0.64));
}

@media (max-width: 840px) {
    .style-menu-wrapper {
        display: none;
    }

    .theme-menu {
        display: none;
    }
}

