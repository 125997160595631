.changed_confirmed_popup_un_active,
.changed_confirmed_popup {
    position: fixed;
    display: grid;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 10010;
    top: 6rem;
    opacity: 0;
    visibility: hidden;
    transition: .4s;
    width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.changed_confirmed_popup {
    opacity: 1;
    visibility: visible;
}

.changed_confirmed_popup_un_active .changing_confirmed span,
.changed_confirmed_popup .changing_confirmed span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #2F49D1;
}

.changed_confirmed_popup_un_active .changing_confirmed img,
.changed_confirmed_popup .changing_confirmed img {
    height: 28px;
}

.changed_confirmed_popup_un_active .changing_confirmed,
.changed_confirmed_popup .changing_confirmed {
    background: #EEF0FB;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
    border-radius: 8px;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
    padding: 8px 40px;
    width: 100%;
}

.changed_confirmed_popup_un_active {
    visibility: hidden;
}

@media  (max-width: 568px) {
    .changed_confirmed_popup_un_active .changing_confirmed,
    .changed_confirmed_popup .changing_confirmed {
        grid-gap: .5rem;
        padding: 8px;
    }
}