.payCard {
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.modal-content-payment .more-info {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    /*border: 1px solid #cbd5e1;*/
    /*border-radius: 8px;*/
    padding: 2px 16px;
    transition: .2s;
    cursor: pointer;
}

.payCard:disabled {
    /* your styling here */
    opacity: 0.5; /* example: reduce opacity to indicate disabled state */
    cursor: not-allowed; /* example: change cursor to indicate disabled state */
}