.modalPayment {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
}

.modalPayment.active{
    background: rgba(0, 0, 0, 0.2);
}


.modal-content-payment {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 27px;
}

.modalPayment .close_icon {
    cursor: pointer;
}

.modalPayment.active, .modalPayment.active .modal-content-payment {
    transform: scale(1);
    transition: 0.5s;
}



.close-and-feedback .close-button{
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-feedback{
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.close-and-feedback .leave-feedback{
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    color: #475569;
}

.modal-content-payment .set-of-paid{
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    padding-top: 17px;
    align-items: center;
}

.modal-content-payment .set-of-paid .type-paid{
    display: grid;
    grid-gap: 8px;
}

.modal-content-payment .set-of-paid .type-paid .set-service{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    word-break: break-all;
    width: 250px;
}


.modal-content-payment .set-of-paid .type-paid .type{
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.modal-content-payment .set-of-paid .price{
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #2f49d1;
}



.modal-content-payment .applePay{
    background-color: #000000;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-family: Montserrat,sans-serif;
    display: flex;
    justify-content: center;
}

.modal-content-payment .payByCard:hover,
.modal-content-payment .googlePay:hover,
.modal-content-payment .applePay:hover{
    opacity: 0.9;

}

.modal-content-payment .googlePay{
    background-color: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: #3C4043;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 8px;
    font-family: Montserrat,sans-serif;
}

.modal-content-payment .payByCard{
    background-color: #FFFFFF;
    border: 1px solid #2F49D1;
    border-radius: 8px;
    color: #2F49D1;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 8px;
    font-family: Montserrat,sans-serif;
    margin-top: 16px;
}

@media (max-width: 500px) {
    .modal-content-payment .set-of-paid .type-paid .set-service{
        width: 220px;
    }
}

@media (max-width: 435px) {
    .modal-content-payment .set-of-paid .type-paid .set-service{
        width: 200px;
    }
}


