.modalPurchase {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 7000;
}


.modal-content-purchase {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    position: relative;
    display: grid;
    grid-gap: 15px;
}

.modalPurchase .close_icon {
    cursor: pointer;
}

.modalPurchase.active, .modalPurchase.active .modal-content-purchase {
    transform: scale(1);
    transition: 0.5s;
}


.modal-content-purchase{
    padding: 24px;
    text-align: center;
}

.modal-content-purchase .thank-you{
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #20C576;
}

.modal-content-purchase .purchase-successful{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.modal-content-purchase .confetti{
    margin-top: 40px;
}

.modal-content-purchase .ok-button{
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    padding: 12px 150px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.modal-content-purchase .ok-button:hover{
    opacity: 0.9;
}
@media (max-width: 435px) {
    .modal-content-purchase{
        width: 343px;
        justify-content: center;
    }
    .modal-content-purchase .confetti{
        width: 300px;
    }
}



