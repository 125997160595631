.modalLogout {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
}

.modal-content-logout {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 15px;
    z-index: 5000;
}

.modalLogout .close_icon {
    cursor: pointer;
}

.modalLogout.active, .modalLogout.active .modal-content-logout {
    transform: scale(1);
    transition: 0.5s;
}

.close-and-feedback .close-button {
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-feedback {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.close-and-feedback .leave-feedback {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
}

.modal-content-logout .sure-delete {
    display: grid;
    grid-gap: 10px;
    margin-top: 10px;
    color: #94A3B8;
}

.modal-content-logout .delete-block .delete {
    background: #FFFFFF;
    border-radius: 8px;
    color: #2F49D1;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #2F49D1;
    text-align: center;
}


.modal-content-logout .cancel-block .cancel {
    background: #2F49D1;
    border-radius: 8px;
    text-align: center;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
}
.modal-content-logout .cancel-block .cancel:hover{
    opacity: 0.9;
}

.modalLogout .modal-content-logout .log span {
    font-weight: bold !important;
    font-size: 24px !important;
    line-height: 36px !important;
    color: #475569 !important;
}

@media (max-width: 430px) {
    .modal-content-logout {
        width: 343px;
    }

    .close-and-feedback .leave-feedback {
        font-weight: bold;
        font-size: 17px;
        line-height: 36px;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 16px;
        align-items: center;
    }

    }



