.modalError {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 7000;
}


.modal-content-error {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 15px;
    justify-items: center;
}

.modalError.active, .modalError.active .modal-content-error {
    transform: scale(1);
    transition: 0.5s;
}


.modal-content-error{
    padding: 24px;
    text-align: center;
}

.modal-content-error .went-wrong{
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #F26464;
}

.modal-content-error .purchase-error{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.modal-content-error .petting{
    margin-top: 40px;
}

.modal-content-error .ok-button{
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    border: none;
    padding: 12px 150px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}



