.modalDelete {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
    padding: 1rem;
}

.modalDelete.active {
    background: rgba(0, 0, 0, 0.5);
}

.modal-content-delete {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 15px;
}

.modalDelete .close_icon {
    cursor: pointer;
}

.modalDelete.active, .modalDelete.active .modal-content-delete {
    transform: scale(1);
}

.close-and-feedback .close-button {
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-feedback {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.close-and-feedback .leave-feedback {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
}

.modal-content-delete .sure-delete {
    display: grid;
    grid-gap: 10px;
    margin-top: 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.modal-content-delete .delete-block .delete {
    background: #FFFFFF;
    border-radius: 8px;
    color: #2F49D1;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #2F49D1;
    font-family: 'Montserrat', sans-serif;
}

.modal-content-delete .cancel-block .cancel {
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
    font-family: 'Montserrat', sans-serif;
}

.modal-content-delete .cancel-block .cancel:hover {
    opacity: 0.9;
}

@media (max-width: 400px) {
    .modal-content-delete {
        width: 100%;
        padding: 1.5rem 1rem;
    }

    .close-and-feedback .leave-feedback {
        font-weight: bold;
        font-size: 17px;
        line-height: 36px;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: 16px;
        align-items: center;
    }
}