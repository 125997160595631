.modalRaise {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 1005;
}

.modalRaise.active {
    background: rgba(0, 0, 0, 0.5);
}

.modal-content-raise {
    transition: 0.5s;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 5px;
}

.modalRaise .close_icon {
    cursor: pointer;
}

.modalRaise.active, .modalRaise.active .modal-content-raise {
    transform: scale(1);
    transition: 0.5s;
}


.close-and-feedback .close-button {
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-feedback {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.modal-content-raise .paid-service {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    /*margin-top: 40px;*/
}

.modal-content-raise .paid-service .paid {
    display: grid;
    grid-gap: 8px;
}

.modal-content-raise .paid-service .fee {
    padding-right: 26px;
}

.modal-content-raise .raiseAd-to-top {
    padding: 24px;
}

.modal-content-raise .paid-service .fee span {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #2F49D1;
}

.modal-content-raise .paid-service .paid .paid-service-word {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.modal-content-raise .paid-service .paid .top-fee {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
}

.modal-content-raise .pay-and-balance {
    padding: 0 24px;
}

.payment-options {
    text-align: center;
}

.payment-options > * {
    display: block;
    margin: 10px 0;
}

.modal-content-raise .balance-and-tops {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
}

.modal-content-raise .pay-and-balance .balance {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 8px;
    align-items: center;
}

.modal-content-raise .pay-and-balance .balance span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.modal-content-raise .pay-and-balance .balance-and-tops .tops {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #F57C1F;
}

.modal-content-raise .pay-and-balance .pay {
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
    font-family: 'Montserrat', sans-serif;
}

.modal-content-raise .pay-and-balance .payByCard:hover,
.modal-content-raise .pay-and-balance .googlePay:hover,
.modal-content-raise .pay-and-balance .applePay:hover,
    .modal-content-raise .pay-and-balance .pay:hover {
    opacity: 0.9;
}

.modal-content-raise .pay-and-balance .balance-and-tops .or {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.modal-content-raise .pay-and-balance .applePay {
    background: #000000;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
}

.modal-content-raise .pay-and-balance .googlePay {
    background: #FFFFFF;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    color: #3C4043;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-top: 16px;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 8px;
    font-family: 'Montserrat', sans-serif;

}

.modal-content-raise .pay-and-balance .payByCard {
    background: #FFFFFF;
    border: 1px solid #2F49D1;
    border-radius: 8px;
    color: #2F49D1;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    width: 100%;
    margin-top: 16px;
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: center;
    grid-gap: 8px;
    font-family: 'Montserrat', sans-serif;
}

.payByCard .wallet-unavailable  {
    color: grey;
}

.googlePayment {
    margin-top: 16px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 830px) {
    .modal-content-raise .pay-and-balance,
    .modal-content-raise .raiseAd-to-top {
        padding: 5px 16px;
    }
    .modal-content-raise .paid-service {
        padding: 0 16px;
        min-height: 75px;
    }

    .modalRaise.active {
        padding: 0 16px;
    }
}

@media (max-width: 700px) {
    .modalRaise.active {
        background: rgba(0, 0, 0, 0.1);
    }
}


