.marker_wrapper {
    background: rgba(47, 73, 209, 0.2);
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    display: grid;
    place-items: center;
}

.marker_wrapper div {
    background: #2F49D1;
    width: 15px;
    height: 15px;
    border-radius: 100%;
}