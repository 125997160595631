.sort_mobile_popular{
    background: #E5E5E5;
}
.modalMobMenu .sort_mobile_popular .list_block{
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    grid-gap: 20px;
    transform: scale(1);
}


.modalMobMenu .sort_mobile_list .mob-menu-icon{
    border-bottom: 1px solid #CBD5E1;
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    grid-gap: 20px;
    transform: scale(1);
}

.modalMobMenu .sort_mobile_popular .sortPopularMob{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}
.list_icon{
    display: flex;
    grid-gap: 20px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    border-bottom: 1px solid #CBD5E1;
    padding: 0.7rem 0;

}

.modalMobMenu .sort_mobile_list{
    box-shadow: 0 -2px 8px rgba(47, 73, 209, 0.16);
    border-radius: 8px;
    display: grid;
    bottom: -10px;
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
    padding: 8px 24px;
    animation-duration: 0.55s;
    animation-fill-mode: both;
    animation-name: fadeInDown;
}

.modalMobMenu{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6000;
    transform: scale(0);
}

.modalMobMenu.active {
    transform: scale(1);
}

.modalMobMenu .sort_mobile_popular{
    border-radius: 8px;
    display: grid;
    bottom: -10px;
    position: absolute;
    background-color: #FFFFFF;
    width: 100%;
    padding: 8px 24px;
    animation-duration: 0.55s;
    animation-fill-mode: both;
    animation-name: fadeInDown;
}

.modalMobMenu .sort_mobile_popular .mob-menu-icon{
    border-bottom: 1px solid #CBD5E1;
    padding-bottom: 16px;
    padding-top: 16px;
    display: flex;
    grid-gap: 20px;
    transform: scale(1);
}

.modalMobMenu .sort_mobile_popular .sortPopularMob{
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translate3d(0px, 100%, 0px);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}