.profile_wrapper {
    display: grid;
    grid-template-columns: 302px calc(100% - 302px - 1.5rem);
    grid-gap: 1.5rem;
    margin: 0 0 2.75rem;
    padding: 1.5rem 5rem 0;
}

.profile_wrapper .profile_info {
    background: #FFFFFF;
    border-radius: 8px;
    height: max-content;
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}

.profile_wrapper .profile_page_content {
    position: relative;
}

.profile_wrapper .profile_info .user_info {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    padding: 1.5rem;
}

.profile_wrapper .profile_info .user_info .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #0F172A;
    max-width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.profile_wrapper .profile_info .user_info .ads_count {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
    margin: 0 0 1rem;
}

.profile_wrapper .profile_info .user_info .rating_star {
    display: grid;
    align-items: center;
    width: max-content;
    margin: 0 auto 4px;
}

.profile_wrapper .profile_info .user_info .rating_count {
    font-size: 12px;
    line-height: 16px;
    color: #94A3B8;
}

.profile_wrapper .profile_info .profile_navigation {
    border-top: 1px solid #E2E8F0;
    border-bottom: 1px solid #E2E8F0;
    padding: 1.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: .5rem;
    margin: 0 0 1.5rem;
}

.profile_wrapper .profile_info .profile_navigation .profile_link a, .profile_wrapper .profile_info .log_out button {
    padding: 1rem 1.25rem 1rem 1.5rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 1rem;
    align-items: center;
    transition: .3s;
    border-radius: 8px;
    width: 100%;
    text-align: left;
}

.profile_wrapper .profile_info .profile_navigation .profile_link a:hover, .profile_wrapper .profile_info .log_out button:hover {
    background: #EEF0FB;
}

.profile_wrapper .profile_info .profile_navigation .profile_link a span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    transition: .3s;
}

.profile_wrapper .profile_info .profile_navigation .profile_link a svg path {
    transition: .3s;
    stroke: #475569;
}

.profile_wrapper .profile_info .profile_navigation .profile_link_active a {
    background: #EEF0FB;
}

.profile_wrapper .profile_info .profile_navigation .profile_link_active a span {
    color: var(--primary-blue);
}

.profile_wrapper .profile_info .profile_navigation .profile_link_active a svg path {
    transition: .3s;
    stroke: var(--primary-blue);
}

.profile_wrapper .profile_info .log_out {
    padding: 0 1rem 1.5rem;
}

.profile_wrapper .profile_info .log_out .word-log-out {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    transition: .3s;
    color: #94A3B8;
}

.profile_wrapper .profile_info .log_out button:hover span {
    color: #475569;
}

.profile_wrapper .profile_info .log_out button svg path {
    transition: .3s;
}

.profile_wrapper .profile_info .log_out button:hover svg path {
    stroke: #475569;
}

.empty_list_wrap {
    display: grid;
    place-items: center;
    max-width: 420px;
    margin: 4rem auto 0;
}

.empty_list_wrap img {
    margin: 0 auto 1rem;
    display: block;
}

.empty_list_wrap h5 {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #475569;
    margin: 0 0 .5rem;
}

.empty_list_wrap p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475569;
    margin: 0;
}

.profile_photo {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
}

.profile_initials {
    position: relative;
}

.profile_initials span {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: capitalize;
}

@media (max-width: 1440px) {
    .profile_wrapper {
        padding: 1.5rem 3rem 0;
    }
}

@media (max-width: 992px) {
    .profile_wrapper .profile_info {
        display: none;
    }

    .profile_wrapper {
        display: unset;
    }
}

@media (max-width: 700px) {
    .profile_wrapper {
        padding: unset;
    }
}