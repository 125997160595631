.wrapper-page-not-found {
    display: flex;
    padding: 80px 130px 80px 80px;
    justify-content: space-around;
}

.wrapper-page-not-found .right-block .page-not-found {
    background: #E2E8F0;
    border: 1px solid #E2E8F0;
    border-radius: 100px;
    padding: 8px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    margin-bottom: 24px;
}

.wrapper-page-not-found .right-block .oh-no {
    font-weight: bold;
    font-size: 56px;
    line-height: 84px;
    color: #475569;
}

.wrapper-page-not-found .right-block {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.wrapper-page-not-found .right-block .try-to-find {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #94A3B8;
    max-width: 430px;
}

.wrapper-page-not-found .right-block .back-homepage {
    background: #2F49D1;
    border-radius: 8px;
    padding: 12px 102px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top: 60px;
    font-family: 'Montserrat', sans-serif;
}

@media (max-width: 875px) {
    .wrapper-page-not-found{
        flex-direction: column;
        padding: 30px;
    }
    .wrapper-page-not-found .right-block .oh-no{
        font-size: 34px;
        line-height: 30px;
    }
    .wrapper-page-not-found img{
        width: 297px;
        height: 252px;
    }
    .wrapper-page-not-found .right-block .back-homepage{
        padding: 12px 60px;
    }
}