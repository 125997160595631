.signup-or-reset {
    display: grid;
    grid-gap: 10px;
}

.signup-or-reset span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #94A3B8;
}

.signup-or-reset .forgot-reset {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: .5rem;
}

@media (max-width: 350px) {
    .signup-or-reset .forgot-reset {
        grid-template-columns: 1fr;
        grid-gap: 0;
    }
}