.title-card span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: 680px;
}

.desc-icon {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.price-and-heart {
    display: grid;
    justify-items: end;
    width: 100%;
}

.price-and-heart .heart {
    display: grid;
    align-items: end;
}


.non_active_love {
    color: #2f49d1;
    transition: .3s;
}

.active-love {
    fill: #2f49d1;
    color: #2f49d1;
}

.card_img {
    height: 20px;
    width: 20px;
    /*margin-left: -3px;*/
}

.initial_display {
    width: 18px;
    height: 18px;
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: #CBD5E1;
}

.initial_display img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    border-radius: 100%;
}

.initial_display span {
    font-size: 10px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: capitalize;
}

.user-location div {
    display: flex;
}

.ads_block-img {
    display: block;
    width: 100%;
    height: 224px;
    object-fit: cover;
    position: relative
}

@media (max-width: 1350px) {
    .title-card span {
        width: 500px;
    }
}

@media (max-width: 1160px) {
    .title-card span {
        width: 310px;
    }
}

@media (max-width: 995px) {
    .title-card span {
        width: 290px;
    }
}

@media (max-width: 900px) {
    .user-likes .heart {
        width: 19px;
        height: 20px;
    }

    .user-likes {
        margin-left: 10px;
    }
}
