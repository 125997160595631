.button-sign-up {
    background: #FFFFFF;
    border-radius: 8px;
    width: 155px;
    height: 36px;
    cursor: pointer;
    border: none;
    transition: .3s;
}

.button-sign-up:hover {
    background: #20C576;
}

.button-sign-up span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0F172A;
    transition: .3s;
}

.button-sign-up:hover span {
    color: #FFFFFF;
}

.button-log-in {
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    width: 155px;
    height: 36px;
    background-color: unset;
    cursor: pointer;
    transition: .3s;
}

.button-log-in:hover {
    background: #FFFFFF;
}

.button-log-in span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    font-family: Montserrat, sans-serif;
    transition: .3s;
}

.button-log-in:hover span {
    color: var(--primary-blue);
}

