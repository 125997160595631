.modal {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
}


.modal-content {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 574px;
    position: relative;
    display: grid;
    grid-gap: 15px;
}

.modal .close_icon {
    cursor: pointer;
}

.modal.active, .modal.active .modal-content {
    transform: scale(1);
    transition: 0.5s;
}


.close-and-owner {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
}

.close-and-owner .close-button {
    width: 40px;
    height: 40px;
    background: #F8FAFC;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-and-owner .owner-name {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
}

.close-and-owner .owner-name span {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

textarea {
    display: block;
    width: 100%;
    border: 1px solid #94A3B8;
    border-radius: 8px;
    padding: 8px 16px;
    resize: none;
    outline: none;
    font-size: 16px;
}

textarea::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #CBD5E1;
    font-family: 'Montserrat', sans-serif;
}

.modal-content .file-and-send {
    display: grid;
    grid-template-columns: 1fr max-content;
    justify-content: space-between;
    align-items: end;
}

.modal-content .file-and-send .file {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 8px;
}

.modal-content .file-and-send .file .attach-file {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}

.modal-content .file-and-send .file .attach-file span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.modal-content .file-and-send .file .format {
    font-size: 14px;
    line-height: 21px;
    color: #94A3B8;
}

.modal-content .file-and-send .send {
    background: #2F49D1;
    border-radius: 8px;
    color: #FFFFFF;
    padding: 12px 60px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
    cursor: pointer;
    transition: .2s;
    font-family: 'Montserrat', sans-serif;
}

.message-info {
    background: #EEF0FB;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    width: 20%;
    padding: 3px 12px;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
}

.message-info > span {
    color: #2f49d1;
    font-weight: 600;
    font-size: 18px;
}

.photo-owner {
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    object-fit: cover;
}
@media (max-width: 500px) {
    .modal.active, .modal.active .modal-content {
        padding: 16px;
    }

    .modal-content .file-and-send {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 24px;
    }

    .modal-content .file-and-send .send {
        width: 100%;
    }
}

@media (max-width: 390px) {
    .close-and-owner .owner-name span{
        max-width: 150px;
    }
}

.popup-error {
    position: absolute;
    top: -4.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 60%;
    height: 50px;
    visibility: hidden;
    opacity: 1;
    transition: .2s;
}

.popup-error-active {
    visibility: visible;
    opacity: 1;
    top: -2.8rem;

}