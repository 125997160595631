.MuiPagination-ul {
    margin-bottom: 56px !important;
    margin-top: 20px !important;
}

.MuiPaginationItem-page.Mui-selected {
    background: #FFFFFF !important;
    border: 2px solid #2F49D1;
    border-radius: 100px;
}

@media (max-width: 768px) {
    .MuiPagination-ul {
        margin-left: -5px !important;
        margin-top: 30px !important;

    }
}