.footer {
    width: 100%;
    height: 84px;
    background: #2f49d1;
    display: grid;
    grid-template-columns: repeat(3, auto);
    align-items: center;
    justify-content: space-between;
}

.footer a,
.footer span {
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    text-decoration: none;
}

.footer-text {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 32px;
    margin-left: 80px;
}

.footer-text3 .logo-footer {
    margin-right: 95px;
    cursor: pointer;
}

.policy-landing {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
    grid-gap: 108px;
}

.policy-landing a {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    padding-left: 1rem;
    transition: .2s;
}

.policy-landing a:hover {
    opacity: .8;
}

.policy-landing .email-and-logo {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 40px;
    align-items: center;
}

.email-and-logo a {
    color: #FFFFFF;
}

@media (max-width: 1366px) {
    .policy-landing {
        grid-gap: 40px;
    }

    .policy-landing .email-and-logo {
        grid-gap: 20px;
    }
}

@media (max-width: 992px) {
    .policy-landing .email-and-logo {
        grid-template-columns: unset;
        grid-gap: 16px;
    }

    .policy-landing a {
        font-weight: 700;
    }

}

@media (max-width: 450px) {
    .policy-landing {
        display: grid;
        grid-template-columns: max-content;
        justify-content: space-between;
        align-items: center;
        grid-gap: 20px;
    }

    .policy-landing .email-and-logo {
        grid-template-columns: unset;
        grid-gap: 5px;
    }

    .policy-landing a {
        font-weight: 600;
    }

}

/* StyledFooterList */
.StyledFooterList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 5rem;
    background-color: #2f49d1;
    color: #ffffff;
}

@media (max-width: 1366px) {
    .StyledFooterList {
        padding: 1rem 3rem;
    }
}

@media (max-width: 1230px) {
    .StyledFooterList {
        padding: 13px 31px;
    }
}

@media (max-width: 1200px) {
    .StyledFooterList {
        flex-direction: column;
        padding: 24px 16px 48px;
        min-height: 400px;
    }
}

/* StyledFooterYear */
.StyledFooterYear {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 24px;
}

.StyledFooterYear > span {
    padding: 0 0.4rem;
    font-size: 0.9rem;
}

@media (max-width: 992px) {
    .StyledFooterYear {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 21px;
    }
}

/* StyledFooterTerms */
.StyledFooterTerms > span {
    display: inline-block;
    list-style-type: none;
    padding-left: 1rem;
    font-size: 0.9rem;
}

@media (max-width: 992px) {
    .StyledFooterTerms {
        display: grid;
        grid-gap: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 21px;
    }
}

/* StyledFooterEmail */
.StyledFooterEmail {
    font-size: 0.9rem;
}

@media (max-width: 992px) {
    .StyledFooterEmail {
        font-size: 14px;
        line-height: 21px;
    }
}

/* StyledFooterLogo */
.StyledFooterLogo {
    margin-right: 20px;
    cursor: pointer;
}

@media (max-width: 992px) {
    .StyledFooterLogo {
        padding-left: 1em;
    }
}

