.log-in-form {
    background-image: url('../LoginAcc/assets/background-login.webp');
    height: var(--app-height);
    overflow-y: auto;
    display: grid;
    justify-content: center;
    align-items: center;
}

.log-in-form .main-form-login {
    display: grid;
    justify-content: center;
    align-items: center;
    justify-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.main-word {
    font-weight: bold;
    font-size: 72px;
    line-height: 120%;
    letter-spacing: 0.1em;
    color: #ffffff;
}

.hyperspace {
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: #ffffff;
    margin-bottom: 28px;
}

.email-password-form {
    background: #ffffff;
    width: 568px;
    display: flex;
    flex-direction: column;
}

.wrapper_tabs .tab {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    /*color: #2f49d1;*/
    color: #94A3B8;
    padding-bottom: 12px;
    padding-top: 16px;
}

.wrapper_tabs .active_tab {
    border-bottom: 4px solid #2f49d1;
    width: 100%;
    color: #2F49D1;
}

.log-in-google {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    text-align: center;
    margin-top: 24px;
}

.wrapper_tabs {
    display: grid;
    justify-content: center;
    grid-gap: 55px;
    grid-template-columns: repeat(2, 1fr);
    border-bottom: 1px solid rgba(0, 62, 71, 0.2);
    width: 100%;
    text-align: center;
    background: #f8fafc;
}

.facebook-google {
    display: grid;
    grid-template-columns: max-content max-content;
    margin-top: 24px;
    grid-gap: 16px;
    margin-bottom: 24px;
    justify-content: center;
}

.facebook {
    background: #496199;
    border-radius: 8px;
    height: 46px;
    display: grid;
    grid-template-columns: 50px max-content;
    align-items: center;
    justify-items: center;
    grid-gap: 12px;
    width: 168px;
    cursor: pointer;
}

.facebook-google span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.google {
    background: #4f84ec;
    border-radius: 8px;
    height: 46px;
    display: grid;
    grid-template-columns: 50px max-content;
    align-items: center;
    justify-items: center;
    grid-gap: 12px;
    width: 168px;
    cursor: pointer;
}

.or {
    text-align: center;
    color: #94a3b8;
}

.big-form-registration,
.big-form {
    width: 100%;
    padding: 24px 40px 40px 40px;
}

.big-form input[value],
.big-form input[type="password"],
.big-form input[type="email"] {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #0F172A;
}


.big-form-registration input[value] {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
}

.big-form input::placeholder {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #CBD5E1;
}

.big-form-registration input::placeholder {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #CBD5E1;
}

.big-form-registration .email,
.big-form-login .email {
    text-align: left;
    color: #475569;
    font-size: 14px;
    line-height: 21px;
}

.log-in-button {
    background: #2f49d1;
    border-radius: 8px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    color: #ffffff;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    transition: 300ms;
}

.log-in-button:hover {
    opacity: 0.9;
    transform: scale(1);
}

.password-icon {
    position: relative;
}


@media (max-width: 580px) {
    .log-in-form .main-word {
        display: none;
    }

    .big-form-registration, .big-form {
        padding: 1.5rem 1.5rem 2.5rem;
    }

    .log-in-form .hyperspace {
        display: none;
    }

    .log-in-form .email-password-form {
        width: 100%;
    }

    .log-in-form {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0 1rem;
    }

    .log-in-form .main-form-login {
        width: 100%;
        grid-template-columns: 1fr;
    }
}

@media (max-width: 440px) {
    .big-form-registration, .big-form {
        padding: 16px;
    }

    .wrapper_tabs {
        grid-gap: unset;
    }

    .facebook-google {
        grid-gap: 4px;
    }

}

@media (max-width: 410px) {
    .facebook-google {
        grid-template-columns: max-content;
        grid-gap: 12px;
        justify-content: center;
    }
}