.modalDeleteAd {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 6000;
}

.modalDeleteAd.active {
    background: rgb(12 10 10 / 12%);
}
.modal-content-delete {
    transition: 0.5s;
    padding: 24px;
    border-radius: 12px;
    background-color: white;
    width: 428px;
    position: relative;
    display: grid;
    grid-gap: 15px;
}

.modalDeleteAd .close_icon {
    cursor: pointer;
}

.modalDeleteAd.active, .modalDeleteAd.active .modal-content-delete {
    transform: scale(1);
}



