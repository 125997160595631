header {
    width: 100%;
}

.searchName {
    position: relative;
}

.hero-header {
    background-color: #2f49d1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1100;
}

.hero-header .nav-bar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    height: auto;
    width: 100%;
}

.searchName input:focus {
    background-color: #ffffff;
}

.searchName input {
    display: block;
    height: 44px;
    width: 560px;
    outline: none;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    border-radius: 8px;
    padding: 4px 12px 4px 45px;
    font-family: 'Montserrat', sans-serif;
}

.searchName input::placeholder {
    font-size: 16px;
    line-height: 24px;
    padding-left: 1px;
    color: rgba(255, 255, 255, 0.3);
    font-family: 'Montserrat', sans-serif;
}

.searchName .search {
    position: absolute;
    top: 9px;
    left: 12px;
    cursor: pointer;
    transition: .2s;
    display: grid;
    align-items: center;
}

.search svg path {
    stroke: #FFFFFF;
    transition: .2s;
}

.color-text-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
    z-index: 1;
    transition: .2s;
}

.search_active svg path, .color-text-input:focus + .search svg path {
    stroke: #94A3B8;
}

.color-text-input:focus, input.color-text-input-active {
    color: #475569;
    background-color: #FFFFFF;
}

.createAd-button {
    background: #ffffff;
    border-radius: 8px;
    border: none;
    width: 155px;
    height: 36px;
    cursor: pointer;
    color: #0f172a;
    margin-left: 30px;
    transition: .2s;
    display: grid;
    place-items: center;
}

.createAd-button:hover {
    background-color: #20c576;
    color: #FFFFFF;
}

.createAd-button span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.createAd-button-mobile {
    background: #ffffff;
    border-radius: 8px;
    border: none;
    width: 155px;
    height: 36px;
    cursor: pointer;
    color: #0f172a;
    margin-left: 30px;
    transition: .2s;
    display: grid;
    place-items: center;
}

.createAd-button-mobile:hover {
    background-color: #20c576;
    color: #FFFFFF;
}

.createAd-button-mobile span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.notifications-icon img {
    padding-left: 1rem;
}

.createAd-icons .message-header,
.createAd-icons .bell,
.createAd-icons .heart-header {
    margin-left: 12px;
    cursor: pointer;
    transition: 300ms;

}

.createAd-icons .bell:hover,
.createAd-icons .heart-header:hover,
.createAd-icons .message-header:hover {
    opacity: 0.9;
    transform: scale(1.1);
}

.createAd-icons .outside_click_wrapper,
.createAd-icons {
    display: flex;
    grid-gap: 15px;
    position: relative;
}

.bell {
    cursor: pointer;
}

.notifications {
    width: 328px;
    background: #ffffff;
    box-shadow: 0 2px 8px rgba(47, 73, 209, 0.16);
    border-radius: 8px;
    position: absolute;
    top: 3rem;
    z-index: 5;
    padding: 16px;
    right: 0;
}

.notifications .new-notifications {
    border-bottom: 1px solid #e2e8f0;
    padding: 0 0 16px;
    display: grid;
    grid-template-columns: repeat(2, max-content);

    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
}

.notifications .new-notifications .count-notification {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}

.notifications .new-notifications .clear {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
    grid-gap: 2px;
    cursor: pointer;
}

.notifications .new-notifications .clear span {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #94A3B8;
}

.notifications-content-wrapper {
    max-height: 300px;
    overflow-y: auto;
}

.notifications-content-wrapper > .content .older_notification {
    border-top: 1px solid #E2E8F0;
    padding: 1rem 0 0;
    margin: 1rem 0 0;
}

.notifications-content-wrapper > .content .older_notification > span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #475569;
}

.notifications-about {
    padding-top: 16px;
    display: flex;
    align-items: flex-start;
}

.notifications-about span {
    font-size: 14px;
    line-height: 21px;
    color: #475569;
}

.notifications-about .sender_avatar {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    background: #CBD5E1;
    display: grid;
    place-items: center;
    align-items: center;
    justify-content: center;
    margin-right: .5rem;
}

.notifications-about .sender_avatar img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    object-fit: cover;
    user-select: none;
}

.notifications-about .sender_avatar span {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: capitalize;
}

.content-messages-wrapper {
    position: relative;
}

.content-followed, .content-messages {
    position: absolute;
    background: #F57C1F;
    min-width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;
    padding: 0 6px;
    z-index: 1;
    flex-wrap: wrap;
    transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
}

.content-messages span, .content-followed span {
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
}

.search_result_wrap {
    overflow-y: hidden;
    border-radius: 12px;
    position: absolute;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    z-index: 3000;
    width: 100%;
    /*flex: 100%;*/
}

@media screen {
    
}

.search-result {
    overflow-y: auto;
    width: 100%;
    max-height: 200px;
}

/*.serach-result .product-list {*/
/*    width: 100%;*/
/*    height: 50px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    color: #475569;*/
/*    !*flex-wrap: wrap;*!*/
/*}*/

.product-list .product-item {
    display: block;
    border-bottom: 1px solid #e2e8f0;
    color: #475569;
    padding: 14px 14px;
    /*flex: 100%;*/
}

.product-list .product-item:hover {
    background-color: lightgrey;
}

.cancel-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1rem;
    cursor: pointer;
}

.nav-sidebar {
    padding: 40px 40px;
    position: fixed;
    top: 60px;
    z-index: 200;
    display: flex;
    flex-flow: column;
    height: 100%;
    background: #F8FAFC;
    overflow-y: auto;
    right: -100%;
    width: 100%;
    transition: .8s;
    text-align: center;
}

.nav-sidebar.active {
    right: 0;
    transition: .4s;
}

.main-logo {
    align-items: center;
    aspect-ratio: 1;
    /*padding-left: 6px;*/
    /*padding-right: 12px;*/
}

.nav-sidebar.active .main-word {
    font-weight: bold;
    font-size: 48px;
    line-height: 120%;
    color: #0F172A;
}

.nav-sidebar.active .hyperspace {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #94A3B8;
}

.nav-sidebar.active .sign-up {
    margin-top: 60px;
    background: #2F49D1;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 12px;
    cursor: pointer;
}

.nav-sidebar.active .log-in {
    border: 1px solid #2F49D1;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2f49d1;
    padding: 12px;
    margin-top: 16px;
    cursor: pointer;
}


.input-bar {
    position: fixed;
    right: -100%;
    color: white;
}

.input-bar::placeholder {
    color: rgba(255, 255, 255, 0.3);
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.input-bar.active {
    right: 0;
    transition: .3s;
    height: 24px;
    background: #2F49D1;
    border: none;
    width: 100%;
    padding-left: 60px;
}

.search.active {
    position: absolute;
    top: 26px;
    z-index: 500;
    left: 24px;
    background-color: #2F49D1;
}

.person-img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}

.nav-sidebar .person-header {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
}

.nav-sidebar .person-createAd {
    display: flex;
    flex-direction: column;
}

.nav-sidebar .person-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #2f49d1;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
}

.nav-sidebar .createAd {
    width: 100%;
    background-color: #2F49D1;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 12px;
    border-radius: 8px;
    margin-top: 24px;
    cursor: pointer;
}

.nav-sidebar .menu-bar {
    margin-top: 24px;
    display: grid;
    grid-gap: 24px;
    margin-bottom: 50px;
}

.nav-sidebar .menu-bar .my-rate {
    border-top: 1px solid #E2E8F0;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    cursor: pointer;
    padding-top: 24px;
}

.nav-sidebar .menu-bar .my-rate .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.nav-sidebar .menu-bar .my-menu {
    display: grid;
    grid-template-columns: max-content  max-content;
    grid-gap: 0 16px;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.notifications_mob_number {
    color: #FFFFFF;
    background: #F57C1F;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    right: 0;
}


.nav-sidebar .menu-bar .my-menu-settings .title,
.nav-sidebar .menu-bar .my-menu .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #475569;
}

.nav-sidebar .menu-bar .my-menu-settings {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 16px;
    align-items: center;
    cursor: pointer;
    border-top: 1px solid #E2E8F0;
    padding-top: 24px;
}

.MuiPopover-root {
    z-index: 6000 !important;
}

.notifications_menu {
    right: -120%;
    display: unset;
    position: fixed;
    top: -6px;
    transition: 1s;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.notifications_menu.active {
    display: unset;
    position: fixed;
    top: -6px;
    right: 0;
}

.rating_num {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #94A3B8;
    margin-left: 8px;
}

.main-no-active {
    display: none;
}

.burger-no-active {
    display: none;
}

.change-icon {
    display: none;
}

.no_active_logo {
    display: none;
}

@media screen and (min-width: 830px) {
    .nav-sidebar.active {
        display: none;
    }

    .notifications_mob_number {

    }
}

@media screen and (max-width: 1360px) {
    .d1 input {
        width: 400px;
    }
}

@media (max-width: 1200px) {
    .hero-header .nav-bar-header {
        padding: 1rem 3rem;
    }
}

@media (max-width: 1184px) {
    .searchName input {
        width: 400px;
    }

}

@media (max-width: 1010px) {
    .searchName input {
        width: 350px;
    }
}

@media (max-width: 950px) {
    .searchName input {
        width: 235px;
    }
}

@media (max-width: 830px) {
    .notifications-about {
        padding: 0 0 16px;
        display: flex;
        align-items: flex-start;
        margin: 0 0 1rem;
        border-bottom: 1px solid #E2E8F0;
    }

    .notifications-about .sender_avatar {
        margin-right: .75rem;
    }

    .burger-menu-wrapper {
        position: relative;
    }

    .burger-menu-wrapper .burger-new-notification {
        position: absolute;
        bottom: -2px;
        right: -2px;
        background: #F57C1F;
        width: 12px;
        height: 12px;
        border-radius: 100%;
    }

    .burger-menu {
        cursor: pointer;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 4px;
        padding: 5px 4px;
        align-items: center;
    }

    .burger-menu div {
        width: 16px;
        height: 2px;
        background-color: #FFFFFF;
        transition: .4s;
        border-radius: 5px;
    }

    .burger-menu.active div:first-of-type {
        transform: rotate(135deg) translate(5px, -5px);
    }

    .burger-menu.active div:nth-of-type(2) {
        opacity: 0;
        transform: translateX(200%);
    }

    .burger-menu.active div:nth-of-type(3) {
        transform: rotate(-135deg) translate(4px, 4px);
    }

    .hero-header .button-log-in,
    .hero-header .button-sign-up {
        display: none;
    }

    .burger-and-bazar {
        display: grid;
        grid-template-columns: max-content max-content 1fr;
        grid-gap: 8px;
        align-items: center;
    }

    .hero-header .nav-bar-header {
        height: auto;
        padding: 0.5rem 1rem;
    }

    .search_result_wrap {
        width: 70%;
    }
}


@media (min-width: 840px) {
    .input-bar {
        display: none;
    }

}

@media (max-width: 830px) {
    .searchName input {
        border: none;
        background-color: unset;
        width: unset;
    }

    .searchName input::placeholder {
        color: #2F49D1;
    }

    .search {
        position: unset;
        cursor: pointer;
    }

    .search-result {
        left: unset;

    }

    .createAd-button {
        display: none;
    }

    .createAd-icons {
        display: none;
    }


}
