.header_mod_close {
    background-color: #2F49D1;
    padding: 18px 16px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    align-items: center;
}

.header_mod_close span {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #FFFFFF;
}